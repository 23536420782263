import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { FooterSection } from './type'

export interface LinkSectionProps extends FooterSection {
  length: number
}

const linkSection = Bem('LinkSection')

export const LinkSection = ({
  length = 1,
  title = '',
  links = [],
}: LinkSectionProps) => {
  return (
    <div data-testid="LinkSection" className={linkSection({ length })}>
      <h5 className={linkSection('Title')} data-testid="link-section-title">
        {title}
      </h5>

      <div className={linkSection('LinksWrapper')}>
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.url ?? ''}
            className={linkSection('Link')}
            target={link.openInNewTab ? '_blank' : '_self'}
            rel={link.openInNewTab ? 'noreferrer' : ''}
          >
            {link.title}
          </Link>
        ))}
      </div>
    </div>
  )
}
