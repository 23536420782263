import { useEffect } from 'react'
import Cookie from 'js-cookie'
import { useSearchParams } from '@remix-run/react'

const UTMQueryStringKeys: string[] = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]
// URL query strings for tracking code.
const trackingCodeKeys: string[] = ['partnerref', 'code', 'trackingcode']
export const trackingCodeString = 'TrackingCode:'
/**
 * Call to create UTM cookie (utm_parameters) for ELN
 */
export function useUtmParameter() {
  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const cookieValue = createCookieValueForUTM(searchParams)
      if (cookieValue) {
        Cookie.set('utm_parameters', `${cookieValue}`)
      }
    }
  })
}

/**
 * Convert utm querystrings into ELN supported cookie format.
 * e.g. utm_source:utm_source_value1,utm_medium:utm_medium_value,utm_campaign:utm_campaign_value,utm_term:utm_term_value,utm_content:utm_content_value
 * @param searchParams URL querystrings object
 * @returns UTM parameters cookie value
 */
export function createCookieValueForUTM(searchParams: URLSearchParams): string {
  const keyValue: string[] = []
  // Create cookie string
  // if querystring has any utm parameter
  // else return empty string.
  if (
    Array.from(searchParams.keys()).find(qKey =>
      UTMQueryStringKeys.includes(qKey.toLowerCase()),
    )
  ) {
    const entries = searchParams.entries()
    let curr = entries.next()
    while (!curr.done) {
      if (UTMQueryStringKeys.includes(curr.value[0].toLowerCase())) {
        keyValue.push(`${curr.value[0]}:${curr.value[1]}`)
      }
      curr = entries.next()
    }
  }
  // If URL doesn't contain any utm paramters check if we have utm_parameters
  // cookie and use existing values of utm parameters.
  if (keyValue.length == 0) {
    const utmCookieValue = Cookie.get('utm_parameters')
    utmCookieValue?.split(',').map(val => {
      if (!val.startsWith(trackingCodeString)) {
        keyValue.push(val)
      }
      return keyValue
    })
  }
  // if querystring has any tracking code add it into cookie
  // else return empty string.
  if (
    Array.from(searchParams.keys()).find(qKey =>
      trackingCodeKeys.includes(qKey.toLowerCase()),
    )
  ) {
    const trackingCodes: string[] = []
    const entries = searchParams.entries()
    let curr = entries.next()
    while (!curr.done) {
      const key = curr.value[0].toLowerCase()
      if (trackingCodeKeys.includes(key)) {
        trackingCodes.push(curr.value[1])
      }
      curr = entries.next()
    }
    if (trackingCodes) {
      keyValue.push(`${trackingCodeString}${trackingCodes.join('|')}`)
    }
  }
  return keyValue.join(',')
}
