import { FC, SVGProps } from 'react'

import { ClassName } from '@scm/ui-tailwind'

export interface BaseProps extends SVGProps<SVGSVGElement> {
  className?: ClassName
  size?: number
  sizeheight?: number
}

export type IconProps = Omit<
  BaseProps,
  | 'width'
  | 'height'
  | 'fill'
  | 'focusable'
  | 'role'
  | 'xmlSpace'
  | 'xmlns'
  | 'xmlnsXlink'
>

export type IconComponent<ExtraProps = IconProps> = FC<IconProps & ExtraProps>

export const pickProps = (props: IconProps) => ({
  ...props,
  'aria-hidden': 'true' as SVGProps<SVGSVGElement>['aria-hidden'],
  focusable: 'false' as SVGProps<SVGSVGElement>['focusable'],
  role: 'img',
  xmlSpace: 'preserve',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
  fill: 'currentColor' as SVGProps<SVGSVGElement>['fill'],
  height: (props.sizeheight || '100%') as SVGProps<SVGSVGElement>['height'],
  width: props.size,
})
