import { Ads } from '@scm/ads'
import { Bem } from '@scm/ui-core'

import { SponsorLogosProps } from './type'

const sponsorLogos = Bem('SponsorLogos')

export const SponsorLogos = ({ logos }: SponsorLogosProps) => {
  return (
    <div className={sponsorLogos()} data-component="sponsor-logos">
      {logos && logos.map(pos => <Ads.Ad pos={pos} key={pos} />)}
    </div>
  )
}
