import { useNavigate } from '@remix-run/react'
import { FC } from 'react'

import { Button } from '@scm/ui-component-button'
import { Icon } from '@scm/ui-component-icon'
import { Bem } from '@scm/ui-core'

import { LoggedInProps } from '../type'

interface MegaMenuProps {
  onSignOut: LoggedInProps['onSignOut']
}

const loggedInMegaMenu = Bem('LoggedInMegaMenu')

export const MegaMenu: FC<MegaMenuProps> = ({ onSignOut }: MegaMenuProps) => {
  const navigate = useNavigate()

  const handleSignOut = () => {
    onSignOut()
  }

  const handleEdit = () => {
    navigate('/profile')
  }

  return (
    <div className={loggedInMegaMenu()} data-testid="logged-in-mega-menu">
      <div
        className={loggedInMegaMenu('Buttons')}
        data-testid="logged-in-mega-menu-buttons"
      >
        <button
          type="button"
          className={loggedInMegaMenu('Button')}
          onClick={handleEdit}
          data-testid="logged-in-mega-menu-edit"
        >
          <Icon icon="User" className={loggedInMegaMenu('ButtonIcon')} />

          <span className={loggedInMegaMenu('ButtonText')}>My Account</span>
        </button>

        <button
          type="button"
          className={loggedInMegaMenu('Button')}
          onClick={handleSignOut}
          data-testid="logged-in-mega-menu-logout"
        >
          <span className={loggedInMegaMenu('ButtonText')}>Log Out</span>
        </button>
      </div>

      <div
        className={loggedInMegaMenu('ButtonsMobile')}
        data-testid="logged-in-mega-menu-buttons-mobile"
      >
        <Button
          url="/profile"
          size="medium"
          variant="dark-outlined"
          leftIcon="User"
          className={loggedInMegaMenu('ButtonMobile')}
          textClassName={loggedInMegaMenu('ButtonMobileText')}
          onClick={handleEdit}
          automationId="logged-in-mega-menu-edit"
        >
          My Account
        </Button>

        <Button
          size="medium"
          variant="dark-outlined"
          className={loggedInMegaMenu('ButtonMobile')}
          textClassName={loggedInMegaMenu('ButtonMobileText')}
          onClick={handleSignOut}
          automationId="logged-in-mega-menu-logout"
        >
          Log Out
        </Button>
      </div>
    </div>
  )
}
