import { useEffect, useState, useRef } from 'react'
import { MainMenuItem } from '@scm/ui-module-navigation'
import { fetchNavigationRecentContent } from '~/client/fetchData.client'
import { NavigationRecentContentItem } from '~/client/types'

export const INTERVAL = 900000 // 15 minutes in milliseconds

const getUpdatedNavItems = (
  updatedMainMenuItems: MainMenuItem[],
  navigationRecentContent: NavigationRecentContentItem[],
) =>
  updatedMainMenuItems.map(item => {
    const matchingNavItem = navigationRecentContent.find(
      navItem => navItem.url === item.url,
    )
    item.recentContents = matchingNavItem?.recentContents || []
    return item
  })

export const useIntervalUpdateNavigation = (
  mainMenuItems: MainMenuItem[],
  cloudflareCachingEnabled: boolean,
) => {
  const [updatedMainMenuItems, setUpdatedMainMenuItems] =
    useState(mainMenuItems)
  const intervalRef = useRef<NodeJS.Timer | undefined>()

  const mainMenuLinks = mainMenuItems.map(item => {
    return {
      url: item.url,
      hasSubMenu: item.hasSubMenu && Boolean(item.subMenu.length),
    }
  })

  useEffect(() => {
    if (!cloudflareCachingEnabled) {
      return
    }

    const updateMainMenuItems = async () => {
      const navigationRecentContent =
        await fetchNavigationRecentContent(mainMenuLinks)

      const updatedNavItems = getUpdatedNavItems(
        updatedMainMenuItems,
        navigationRecentContent,
      )

      setUpdatedMainMenuItems(updatedNavItems)
    }

    updateMainMenuItems()

    const interval = setInterval(() => {
      updateMainMenuItems()
    }, INTERVAL)

    intervalRef.current = interval

    return () => clearInterval(interval)
  }, [cloudflareCachingEnabled])

  return { updatedMainMenuItems }
}
