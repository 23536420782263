import React from 'react'

import { Bem } from '@scm/ui-core'

export interface SocialSectionProps {
  children: React.ReactNode
  linkSectionLength: number
}

const socialSection = Bem('SocialSection')
export const SocialSection = ({
  children,
  linkSectionLength,
}: SocialSectionProps) => {
  return (
    <div data-testid="social" className={socialSection({ linkSectionLength })}>
      <h5 className={socialSection('Title')}>Follow Us</h5>

      {children}
    </div>
  )
}
