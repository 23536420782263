import { AuthOption } from '@scm/ui-authentication'

export enum RegistrationButtonVariant {
  HEADER = 'header',
  MEGA_MENU = 'megaMenu',
  FOOTER = 'footer',
}

export type RegistrationButtonVariantType =
  | RegistrationButtonVariant.HEADER
  | RegistrationButtonVariant.MEGA_MENU
  | RegistrationButtonVariant.FOOTER

export enum RegistrationReferrerValue {
  MAIN_MENU = 'main_menu',
  MEGA_MENU = 'mega_menu',
  FOOTER_MENU = 'footer_menu',
}

export interface RegistrationProps {
  onRegister: (option?: AuthOption) => void
  onSignIn: (option?: AuthOption) => void
  variant: 'header' | 'megaMenu' | 'footer'
  isPaidContent: boolean
}
