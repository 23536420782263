import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteLoaderData,
  useLocation,
} from '@remix-run/react'
import { store, useStateSetup } from '@scm/redux-store'
import { Provider } from 'react-redux'
import { Ads } from '@scm/ads'
import { OneTrustCookieBanner } from '@scm/onetrust-cookie-banner'
import { GTM } from '@scm/tag-manager'
import {
  AuthenticationProvider,
  authenticationLinks,
} from '@scm/ui-authentication'
import { Spinner } from '@scm/ui-component-spinner'
import { ScreenProvider, tokens } from '@scm/ui-tailwind'
import { Layout } from '@scm/ui-template-layout'
import { VwoScript } from '@scm/vwo'
import { useInterpret } from '@scm/xstate'

import { ErrorBoundary, Favicon } from './components'
import { ForbiddenError } from './errors/forbidden'
import {
  useUtmParameter,
  useBridgedMediaCheck,
  useLocationHistory,
} from './hooks'
import { StyleSheet } from './styles/brands'
import { RootOutletContextData, RouteData } from './types'
import { TopicRouteData } from '~/utils/topicHelper'
import { LoaderDataType, LoaderType } from '~/routes/.server/root'
import { useIntervalUpdateNavigation } from './hooks/useIntervalUpdateNavigation'

export { loader } from '~/routes/.server/root'

import { LinksFunction, MetaFunction } from '@remix-run/node'

import { MetaElement, getRobotsTagContentValue } from '~/utils/meta'
import styles from './styles/generated/styles.generated.css?url'

export const links: LinksFunction = () => [
  { rel: 'preconnect', href: 'https://cdn.cookielaw.org' },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com',
    crossOrigin: 'anonymous',
  },
  { rel: 'preconnect', href: 'https://www.googletagmanager.com' },
  { rel: 'stylesheet', href: styles },
]

export const meta: MetaFunction<LoaderType> = ({ data }) => {
  if (!data) return []

  const tagList: MetaElement[] = [
    { title: '404 – Page Not Found' }, // Default title to 404
    { name: 'robots', content: getRobotsTagContentValue('noindex') },
    { property: 'og:site_name', content: data.siteName },
  ]

  if (data.google?.siteVerification) {
    tagList.push({
      property: 'google-site-verification',
      content: data.google.siteVerification,
    })
  }

  return tagList
}

export { ErrorBoundary }
export default function App() {
  const { search } = useLocation()
  const isWelcomeAdDisabledByQueryParams =
    new URLSearchParams(search).get('welcad') === 'off'

  useLocationHistory()
  useUtmParameter()
  const {
    ads,
    authConfig,
    brand,
    configState,
    environment,
    featureToggles,
    gtmIds,
    isDctsTurnOn,
    layout,
    newRelicJs,
    oneTrust,
    pageContent,
    testAutomationFlags,
    ui,
    vwo,
    authenticationStatus,
    brandConfig,
  } = useLoaderData<LoaderType>() as LoaderDataType

  const { updatedMainMenuItems } = useIntervalUpdateNavigation(
    layout.nav.mainMenuItems,
    featureToggles.cloudflareCachingEnabled,
  )

  if (!brand) {
    throw new ForbiddenError()
  }
  const rootOutletContext: RootOutletContextData = {
    authenticationStatus,
  }
  const data = useRouteLoaderData('routes/$topic.$slug._index') as RouteData
  const isPaidContent = Boolean(data?.template?.paidGating?.isPaidContent)

  const topicData = useRouteLoaderData('routes/$topic._index') as TopicRouteData
  const isBridgedMediaEnabled = useBridgedMediaCheck(
    data,
    topicData,
    featureToggles,
  )

  const isSponsorBarEnabled = Boolean(!ads.disableSponsorBar)

  if (environment === 'staging') {
    // eslint-disable-next-line
    console.log('brandConfig', brandConfig)
  }

  const adsService = useInterpret(Ads.machine, {
    context: {
      networkId: ads.networkId,
      parentAdUnit: ads.parentAdUnit,
      enabledAds: ads.enabledAds,
      welcAdOff: ads.welcAdOff || false,
      isDctsTurnOn,
      isIasPetEnabled: featureToggles.iasPetEnabled,
      disableAds: testAutomationFlags.disableAds,
      disableWelcomeAd:
        testAutomationFlags.disableWelcomeAd ||
        isWelcomeAdDisabledByQueryParams,
      disableBigskyAd: testAutomationFlags.disableBigskyAd,
      disableCookieBanner:
        testAutomationFlags.disableCookieBanner || !oneTrust.siteId,
    },
  })

  useStateSetup({
    brand,
    environment,
    featureToggles,
    testAutomationFlags,
    configState,
    standaloneRegistrationPage: authConfig?.standaloneRegistrationPage,
  })

  const isWelcomeAdEnabled =
    (ads.enabledAds === 'all' || ads.enabledAds.includes('welcome_v')) &&
    !testAutomationFlags.disableWelcomeAd &&
    !ads.welcAdOff &&
    !isWelcomeAdDisabledByQueryParams

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        {newRelicJs && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html: newRelicJs }}
          />
        )}
        <Links />
        {authConfig?.standaloneRegistrationPage && (
          <link rel="prefetch" href="/register" />
        )}
        <StyleSheet
          overrideBrandStyling={testAutomationFlags.overrideBrandStyling}
          brand={ui?.brand}
          font={ui?.font}
        />
        <Favicon brand={brand} />
        {isWelcomeAdEnabled && (
          <noscript
            dangerouslySetInnerHTML={{
              __html: `<style type="text/css">.gptHideContent { opacity: 1!important; }</style>`,
            }}
          />
        )}
        {isBridgedMediaEnabled && (
          <script src="https://loader.bridged.media/bridged-agent.js" />
        )}
        {!testAutomationFlags.disableCookieBanner && oneTrust.siteId ? (
          <OneTrustCookieBanner siteId={oneTrust.siteId} />
        ) : null}
        {vwo?.enabled ? <VwoScript accountId={vwo.accountId} /> : null}
        {featureToggles.iasPetEnabled && (
          <script async src="//static.adsafeprotected.com/iasPET.1.js" />
        )}
        <script async src={Ads.url} type="text/javascript" />
        {authConfig.enabled &&
          authenticationLinks.map((linkConfig, index) => (
            <link {...linkConfig} key={index} />
          ))}
      </head>
      <body
        className={`brand-${ui.brand} ${
          isWelcomeAdEnabled ? 'gptHideContent' : ''
        }`}
      >
        <GTM.Scripts primary={gtmIds.primary} secondary={gtmIds.secondary} />
        <div id="bigsky_v" suppressHydrationWarning={true} />
        <div id="fb-root" suppressHydrationWarning={true} />
        {isWelcomeAdEnabled && <Ads.WelcomeAd />}
        <Provider store={store}>
          <AuthenticationProvider
            {...authConfig}
            withEln={
              featureToggles.elnNewsletter ||
              featureToggles.elnWithoutNewsletter
            }
            theme={ui.theme}
          >
            <Ads.Provider service={adsService}>
              <ScreenProvider screens={tokens.screens}>
                <Spinner />
                <Layout
                  {...layout}
                  nav={{
                    mainMenuItems: updatedMainMenuItems,
                    secondaryMenu: layout.nav.secondaryMenu,
                  }}
                  registrationText={pageContent?.registrationText}
                  isSponsorBarEnabled={isSponsorBarEnabled}
                  isPaidContent={isPaidContent}
                  authConfig={authConfig}
                >
                  <Outlet context={rootOutletContext} />
                </Layout>
              </ScreenProvider>
            </Ads.Provider>
          </AuthenticationProvider>
        </Provider>
        <ScrollRestoration />
        <Scripts />
        <script
          async
          defer
          src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"
        />
        <script async defer src="https://www.instagram.com/embed.js" />
      </body>
    </html>
  )
}
