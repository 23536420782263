import { useState } from 'react'

import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { LargeGroupProps, LargeMenuProps, MenuItemLink } from './type'

const largeGroup = Bem('LargeGroup')

const MAX_LINKS_TOTAL = 20
const MAX_LINKS_IN_COLUMN = 10

export const LargeGroup = ({ megaMenu = [] }: LargeGroupProps) => {
  return (
    <div className={largeGroup()}>
      {megaMenu?.map((menu, index) => (
        <MegaGroup key={`${menu.title}__${index}`} menu={menu} index={index} />
      ))}
    </div>
  )
}

const MegaGroup = ({
  menu,
  index,
}: {
  menu: LargeMenuProps
  index: number
}) => {
  let subMenuLinks1: MenuItemLink[] = []
  let subMenuLinks2: MenuItemLink[] = []
  if (menu.subMenu.length) {
    const filteredSubMenu = menu.subMenu.filter(
      sub => sub.title.trim() !== '' || sub.url.trim() !== '',
    )
    const halfIndex = Math.min(
      Math.ceil(filteredSubMenu.length / 2),
      MAX_LINKS_IN_COLUMN,
    )
    subMenuLinks1 = filteredSubMenu.slice(0, halfIndex)
    subMenuLinks2 = filteredSubMenu.slice(halfIndex, MAX_LINKS_TOTAL)
  }

  return (
    <div className={largeGroup('Section')} key={`${menu.title}__${index}`}>
      <div className={largeGroup('TitleWrapper')}>
        <Link
          className={largeGroup('Title')}
          to={menu?.url || menu?.seeAllLinkUrl || ''}
        >
          {menu.title}
        </Link>
      </div>
      <div className={largeGroup('Content')} data-testid="large-group-content">
        {/* Title Xl */}
        <div className={largeGroup('TitleXlWrapper')}>
          <Link
            to={menu?.url || menu?.seeAllLinkUrl || ''}
            className={largeGroup('TitleXl')}
            data-testid="large-group-title-xl"
          >
            {menu.title}
          </Link>
        </div>
        <nav
          className={largeGroup('Nav')}
          aria-label={`large-desktop-menu-nav-${index}`}
        >
          <MegaLinkList subMenu={subMenuLinks1} />
          <MegaLinkList subMenu={subMenuLinks2} />
        </nav>
      </div>
    </div>
  )
}

const MegaLinkList = ({ subMenu }: { subMenu: MenuItemLink[] }) => {
  if (!subMenu || !subMenu.length) return null

  return (
    <ol className={largeGroup('NavItemsList')}>
      {subMenu.map((sub, subIndex) => (
        <li key={`${sub.title}__${subIndex}`} className={largeGroup('NavItem')}>
          <Link
            className={largeGroup('NavItemLink')}
            to={sub.url}
            data-testid="nav-item-link"
          >
            {sub.title}
          </Link>
        </li>
      ))}
    </ol>
  )
}

const largeGroupMobile = Bem('LargeGroupMobile')

export const LargeGroupMobile = ({ megaMenu = [] }: LargeGroupProps) => {
  const [menuActive, setMenuActive] = useState('')

  return (
    <div className={largeGroupMobile()}>
      {megaMenu?.map((menu, index) => (
        <MegaGroupMobile
          menu={menu}
          index={index}
          setMenuActive={setMenuActive}
          menuActive={menuActive}
          key={`${menu.title}__${index}`}
        />
      ))}
    </div>
  )
}

const MegaGroupMobile = ({
  menu,
  index,
  menuActive,
  setMenuActive,
}: {
  menu: LargeMenuProps
  index: number
  menuActive: any
  setMenuActive: any
}) => {
  let subMenuLinks1: MenuItemLink[] = []
  let subMenuLinks2: MenuItemLink[] = []
  if (menu.subMenu.length) {
    const filteredSubMenu = menu.subMenu.filter(
      sub => sub.title.trim() !== '' || sub.url.trim() !== '',
    )
    const halfIndex = Math.min(
      Math.ceil(filteredSubMenu.length / 2),
      MAX_LINKS_IN_COLUMN,
    )
    subMenuLinks1 = filteredSubMenu.slice(0, halfIndex)
    subMenuLinks2 = filteredSubMenu.slice(halfIndex, MAX_LINKS_TOTAL)
  }
  return (
    <div
      key={`${menu.title}__${index}`}
      className={largeGroupMobile('SectionWrapper', {
        active: menuActive === menu.title,
      })}
    >
      <div
        className={largeGroupMobile('Section')}
        aria-hidden="true"
        onClick={() => {
          menu.title === menuActive
            ? setMenuActive('')
            : setMenuActive(menu.title)
        }}
      >
        {menu.subMenu[0] ? (
          <>
            <Icon icon="ChevronDown" className={largeGroupMobile('Chevron')} />
            <p className={largeGroupMobile('Title')}>{menu.title}</p>
          </>
        ) : (
          <Link
            className={largeGroupMobile('Title', { type: 'link' })}
            to={menu?.url || menu?.seeAllLinkUrl || ''}
          >
            {menu.title}
          </Link>
        )}
      </div>
      {menu.subMenu[0] && (
        <div className={largeGroupMobile('Content')}>
          <nav
            className={largeGroupMobile('Nav')}
            aria-label={`large-mobile-menu-nav-${index}`}
          >
            <div className={largeGroupMobile('NavItemsListWrapper')}>
              <MegaLinkListMobile subMenuLinks={subMenuLinks1} />
              <MegaLinkListMobile subMenuLinks={subMenuLinks2} />
            </div>
            <ol className={largeGroupMobile('NavItemsList')}>
              {menu.seeAllLinkUrl && (
                <li
                  key={`${menu.title}_li_${index}`}
                  className={largeGroupMobile('SeeAllLinkWrapper')}
                >
                  <Link
                    to={menu.seeAllLinkUrl}
                    className={largeGroupMobile('SeeAllLink')}
                  >
                    {menu.seeAllLink || `See All ${menu.title}`}
                    <Icon
                      icon="ChevronRight"
                      className={largeGroupMobile('SeeAllLinkIcon')}
                    />
                  </Link>
                </li>
              )}
            </ol>
          </nav>
        </div>
      )}
    </div>
  )
}

const MegaLinkListMobile = ({ subMenuLinks }: { subMenuLinks: any[] }) => {
  return (
    <ol className={largeGroupMobile('NavItemsList')}>
      {subMenuLinks.map((sub, subIndex) => (
        <li
          className={largeGroupMobile('NavItem')}
          key={`${sub.title}__${subIndex}`}
        >
          <Link className={largeGroupMobile('NavItemLink')} to={sub.url}>
            {sub.title}
          </Link>
        </li>
      ))}
    </ol>
  )
}
