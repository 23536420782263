import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { FooterSection } from './type'

export interface CopyrightsSectionProps {
  businessUnit: {
    url: string
    name: string
    logoUrl: string
  }
  links: FooterSection['links']
  title?: string
}

const copyrightsSection = Bem('CopyrightsSection')

export const CopyrightsSection = ({
  businessUnit,
  links,
  title = 'Informa PLC Informa UK Limited is a company registered in England and Wales with company number 1072954 whose registered office is 5 Howick Place, London, SW1P 1WG.',
}: CopyrightsSectionProps) => (
  <div className={copyrightsSection()}>
    <div className={copyrightsSection('Background')} />
    <div data-testid="copyrights" className={copyrightsSection('Wrapper')}>
      <Link
        data-testid="businessUnit"
        to={businessUnit.url}
        className={copyrightsSection('BusinessLink')}
      >
        <Image
          className={copyrightsSection('BusinessImage')}
          src={businessUnit.logoUrl}
          alt={businessUnit.name}
          title={businessUnit.name}
          fallback="brand"
        />
      </Link>

      <div className={copyrightsSection('TextWrapper')}>
        <p data-testid="copyrightText" className={copyrightsSection('Text')}>
          <span className={copyrightsSection('TextBlock')} />
          {`Copyright © ${new Date().getFullYear()}${title}`}
        </p>

        {links.length > 0 && (
          <div
            data-testid="copyrightLinks"
            className={copyrightsSection('LinksWrapper', {
              hasLinks: links.length > 0,
            })}
          >
            {links.map((link, index) => (
              <Link
                to={link.url ?? ''}
                key={index}
                className={copyrightsSection('Link')}
                rel={link.openInNewTab ? 'noreferrer' : ''}
                target={link.openInNewTab ? '_blank' : '_self'}
              >
                {link.title}
                {links.length === index + 1 ? (
                  ''
                ) : (
                  <span className={copyrightsSection('LinkSeparator')}>|</span>
                )}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
)
