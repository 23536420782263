import React from 'react'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { BrandSection } from './brandSection'
import { CopyrightsSection } from './copyrightsSection'
import { LinkSection } from './linkSection'
import { RegistrationSection } from './registrationSection'
import { SocialSection } from './socialSection'
import { FooterProps } from './type'

export type { FooterProps }

const footer = Bem('Footer')

export const Footer = ({
  logo,
  linkSections,
  social,
  registration,
  brandLinks,
  copyright,
}: FooterProps) => {
  const length = linkSections.length

  return (
    <footer data-module="footer" className={footer()}>
      <div className={footer('Background')} />

      <div data-testid="logo" className={footer('LogoContainer', { length })}>
        <Link to="/">
          <Image
            className={footer('LogoImage', { length })}
            src={logo.url}
            alt={logo.altText}
            title={logo.altText}
            fallback="brand"
          />
        </Link>
      </div>

      {linkSections.map((section, index) => (
        <LinkSection
          key={index}
          length={length}
          title={section.title}
          links={section.links}
        />
      ))}

      {registration && (
        <div
          data-testid="registration"
          className={footer('RegistrationWrapper')}
        >
          <RegistrationSection linkSectionLength={length}>
            {registration}
          </RegistrationSection>
        </div>
      )}

      {social && (
        <SocialSection linkSectionLength={length}>{social}</SocialSection>
      )}

      {brandLinks.length > 0 && <BrandSection links={brandLinks} />}

      <CopyrightsSection {...copyright} />
    </footer>
  )
}
