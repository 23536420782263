import { FC } from 'react'
import { MegaMenu } from './components/megaMenu'
import { Dropdown } from './components/dropdown'
import { LoggedInProps } from './type'

export const LoggedIn: FC<LoggedInProps> = ({
  onSignOut,
  variant,
}: LoggedInProps) => {
  return (
    <div data-module="logged-in">
      {variant === 'header' ? (
        <Dropdown onSignOut={onSignOut} />
      ) : (
        <MegaMenu onSignOut={onSignOut} />
      )}
    </div>
  )
}
