import { useNavigate } from '@remix-run/react'
import { FC, useRef, useState } from 'react'
import { useClickAway } from 'react-use'

import { Icon } from '@scm/ui-component-icon'
import { Bem } from '@scm/ui-core'

import { LoggedInProps } from '../type'

interface DropdownProps {
  onSignOut: LoggedInProps['onSignOut']
}

const loggedInDropdown = Bem('LoggedInDropdown')

export const Dropdown: FC<DropdownProps> = ({ onSignOut }: DropdownProps) => {
  const navigate = useNavigate()
  const rootEl = useRef(null)

  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSignOut = () => {
    onSignOut()
    handleClose()
  }

  const handleEdit = () => {
    navigate('/profile')
    handleClose()
  }

  useClickAway(rootEl, () => {
    if (open) {
      handleClose()
    }
  })

  return (
    <div
      ref={rootEl}
      className={loggedInDropdown()}
      data-testid="logged-in-dropdown"
    >
      <div
        role="button"
        aria-hidden="true"
        className={loggedInDropdown('Toggle')}
        onClick={handleToggle}
        data-testid="logged-in-dropdown-toggle"
      >
        <Icon icon="User" className={loggedInDropdown('ToggleIcon')} />

        <span
          className={loggedInDropdown('ToggleText')}
          data-testid="logged-in-dropdown-toggle-text"
        >
          My Account
        </span>

        <Icon
          icon={open ? 'ChevronUp' : 'ChevronDown'}
          className={loggedInDropdown('Chevron')}
        />
      </div>

      <ul
        className={loggedInDropdown('Menu', { open })}
        data-testid="logged-in-dropdown-menu"
      >
        <li
          role="menuitem"
          aria-hidden="true"
          tabIndex={-1}
          className={loggedInDropdown('MenuItem')}
          onClick={handleEdit}
          data-testid="logged-in-dropdown-menu-edit"
        >
          Edit My Account
        </li>

        <li
          role="menuitem"
          aria-hidden="true"
          tabIndex={-1}
          className={loggedInDropdown('MenuItem')}
          onClick={handleSignOut}
          data-testid="logged-in-dropdown-menu-logout"
        >
          Log Out
        </li>
      </ul>
    </div>
  )
}
