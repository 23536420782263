/* eslint-disable complexity */
import { useReloadWithSpinner } from '@scm/ui-authentication'
import { Button, ButtonProps } from '@scm/ui-component-button'
import { Bem, Brand } from '@scm/ui-core'

import {
  RegistrationButtonVariant,
  RegistrationProps,
  RegistrationReferrerValue,
} from './type'
import { selectors } from '@scm/redux-store'

const registration = Bem('Registration')

export const getButtonVariant = (
  variant: RegistrationProps['variant'],
): ButtonProps['variant'] => {
  switch (variant) {
    case RegistrationButtonVariant.HEADER:
    case RegistrationButtonVariant.FOOTER:
      return 'primary-filled'
    case RegistrationButtonVariant.MEGA_MENU:
      return 'secondary-filled'
    default:
      return 'primary-filled'
  }
}

export const Registration = ({
  onRegister,
  onSignIn,
  variant,
  isPaidContent,
}: RegistrationProps) => {
  const brandLabels = useBrandLabels()
  const registerReferrer = useRegistrationReferrer(variant)
  const reload = useReloadWithSpinner()

  const option = isPaidContent
    ? {
        onRegistrationSuccess: () => reload(),
        onLoginSuccess: () => reload(),
        referrer: registerReferrer,
        withNewsletterPromo: false,
      }
    : {
        onRegistrationSuccess: () => reload(),
        onLoginSuccess: () => reload(),
        referrer: registerReferrer,
      }

  return (
    <div data-module="registration" className={registration({ variant })}>
      <Button
        size="medium"
        variant={getButtonVariant(variant)}
        onClick={() => onRegister(option)}
        className={registration('Register', { variant })}
        automationId="registerButton"
        aria-label={'button-sign-in'}
      >
        <span className={registration('RegisterText', { variant })}>
          {variant === RegistrationButtonVariant.FOOTER
            ? brandLabels.registerTextInFooter
            : brandLabels.registerText}
        </span>
      </Button>

      {variant !== RegistrationButtonVariant.FOOTER && (
        <Button
          size="medium"
          onClick={() => onSignIn(option)}
          leftIcon="User"
          variant="transparent"
          className={registration('SignIn', { variant })}
          automationId="signInButton"
          aria-label={'button-register'}
        >
          Sign in
        </Button>
      )}
    </div>
  )
}

const useRegistrationReferrer = (variant: RegistrationProps['variant']) => {
  switch (variant) {
    case RegistrationButtonVariant.HEADER:
      return RegistrationReferrerValue.MAIN_MENU
    case RegistrationButtonVariant.MEGA_MENU:
      return RegistrationReferrerValue.MEGA_MENU
    case RegistrationButtonVariant.FOOTER:
    default:
      return RegistrationReferrerValue.FOOTER_MENU
  }
}

const useBrandLabels = (): {
  registerText: string
  registerTextInFooter: string
} => {
  const { uiBrand } = selectors.useConfig()

  switch (uiBrand) {
    case Brand.FARM_PROGRESS:
      return {
        registerTextInFooter: 'REGISTER NOW!',
        registerText: 'Sign Up Today',
      }
    case Brand.NATIONAL_HOG_FARMER:
    case Brand.BEEF_MAGAZINE:
    case Brand.FEED_STUFFS:
    case Brand.NATURAL_PRODUCTS_INSIDER:
    case Brand.FOOD_BEVERAGE_INSIDER:
    case Brand.LICENSE_GLOBAL:
    case Brand.SUPPLY_SIDE_SJ:
    case Brand.SUPPLY_SIDE_FBJ:
      return {
        registerTextInFooter: 'Subscribe Today',
        registerText: 'Subscribe Today',
      }
    case Brand.IOT:
    case Brand.AI_BUSINESS:
    case Brand.INFORMATION_WEEK:
    case Brand.QUANTUM:
    case Brand.GAME_DEVELOPER:
    case Brand.DARK_READING: {
      return {
        registerTextInFooter: 'Subscribe Today',
        registerText: 'REGISTER',
      }
    }
    case Brand.NEW_HOPE:
    case Brand.POWDER_BULK_SOLIDS:
    case Brand.DESIGN_NEWS:
    case Brand.BATTERY_TECH:
    case Brand.MDDI:
    case Brand.PLASTICS_TODAY:
    case Brand.PACKAGING_DIGEST:
    case Brand.ORGANIC_PRODUCE_NETWORK: {
      return {
        registerTextInFooter: 'Subscribe Today',
        registerText: 'Sign Up Today',
      }
    }
    case Brand.CONSTRUCTIO_NEXT:
    case Brand.INSIDE_SELF_STORAGE:
    case Brand.WASTE360: {
      return {
        registerTextInFooter: 'Sign Up Today',
        registerText: 'Sign Up Today',
      }
    }
    case Brand.BIO_PROCESS_INTERNATIONAL:
    case Brand.RESTAURANT_HOSPITALITY:
    case Brand.SUPERMARKET_NEWS:
    case Brand.FOOD_SERVICE_DIRECTOR:
    case Brand.WORLD_HEALTH_EXHIBITIONS_INSIGHTS: {
      return {
        registerTextInFooter: 'Subscribe',
        registerText: 'REGISTER',
      }
    }
    case Brand.SEATRADE_CRUISE:
    case Brand.SEATRADE_MARITIME:
    case Brand.VITA_FOODS_INSIGHTS:
    case Brand.FI_GLOBAL:
    case Brand.NATION_S_RESTAURANT_NEWS:
      return {
        registerTextInFooter: 'REGISTER NOW!',
        registerText: 'REGISTER',
      }
    case Brand.SPECIAL_EVENTS:
    case Brand.CATERSOURCE: {
      return {
        registerTextInFooter: 'Sign Up Now',
        registerText: 'REGISTER',
      }
    }
    case Brand.WEALTH_MANAGEMENT: {
      return {
        registerTextInFooter: 'SUBSCRIBE',
        registerText: 'SUBSCRIBE TODAY',
      }
    }
    default:
      return {
        registerTextInFooter: '',
        registerText: '',
      }
  }
}
