import { Fragment, useState } from 'react'

import { CaretIcon } from '@informa/markets-icons'
import { Bem } from '@scm/ui-core'

// @ts-ignore
import informaLogo from './informaLogoWhite.png'

const informaBar = Bem('InformaBar')

export interface InformaBarProps {
  enabled: boolean

  leftSection: {
    description: string
    subMenu: Array<{
      title: string
      url: string
    }>
  }
  rightSection: {
    description: string
  }
}

export const InformaBar = ({
  enabled,
  leftSection,
  rightSection,
}: InformaBarProps) => {
  const [isOpen, setOpen] = useState(false)

  if (!enabled) return null

  const { description, subMenu } = leftSection

  return (
    <div className={informaBar()} data-component="informa-bar">
      <div
        className={informaBar('ContentSection', { hidden: !isOpen })}
        data-testid="informa-bar-content-section"
      >
        <div
          className={informaBar('LeftSection')}
          data-testid="informa-bar-left-section"
        >
          <div>{description}</div>
          <div>
            {subMenu.map((link, index) => (
              <Fragment key={index}>
                <a
                  className={informaBar('SubMenuItem')}
                  data-testid="informa-bar-sub-menu-item"
                  href={link?.url ?? ''}
                  title={link.title}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.title}
                </a>
                {index < subMenu.length - 1 && (
                  <span className={informaBar('Delimiter')}>|</span>
                )}
              </Fragment>
            ))}
          </div>
        </div>
        <div
          className={informaBar('RightSection')}
          data-testid="informa-bar-right-section"
        >
          {rightSection.description}
        </div>
      </div>
      <div
        className={informaBar('LogoSection')}
        data-testid="informa-bar-logo-section"
        role="button"
        tabIndex={0}
        aria-label="Open Informa bar"
        onClick={() => setOpen(!isOpen)}
        onKeyDown={() => setOpen(!isOpen)}
      >
        <div className={informaBar('LogoWrapper')}>
          <img
            className={informaBar('Logo')}
            src={informaLogo}
            alt={'Logo Informa'}
          />
        </div>
        <span className={informaBar('IconWrapper', { closed: !isOpen })}>
          <CaretIcon />
        </span>
      </div>
    </div>
  )
}
