import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { FooterSection } from './type'

export interface BrandSectionProps {
  links: FooterSection['links']
}

const brandSection = Bem('BrandSection')

export const BrandSection = ({ links }: BrandSectionProps) => {
  return (
    <div className={brandSection()}>
      <div className={brandSection('Background')} />
      <div data-testid="brands" className={brandSection('LinksWrapper')}>
        {links.map((link, index) => (
          <Link
            to={link.url ?? ''}
            key={index}
            className={brandSection('Link')}
            target={link.openInNewTab ? '_blank' : '_self'}
            rel={link.openInNewTab ? 'noreferrer' : ''}
            aria-label={link.title}
            title={`Link to ${link.title}`}
          >
            {link.image?.url ? (
              <Image
                src={link.image.url}
                alt={link.image.altText}
                title={link.image.altText}
                className={brandSection('LinkImage')}
                fallback="brand"
              />
            ) : (
              <span className={brandSection('LinkTitle')}>{link.title}</span>
            )}
          </Link>
        ))}
      </div>
    </div>
  )
}
