import { useEffect, useState } from 'react'

export const useOpenTab = () => {
  const [hostName, setHostName] = useState('')

  useEffect(() => {
    if (window) {
      const host = window?.location?.hostname
      setHostName(host)
    }
  }, [])

  const checkUrl = (url: string, hostName: string) => {
    let dataUrl: { target: string | undefined; rel: string | undefined } = {
      target: undefined,
      rel: undefined,
    }
    if (url.includes('http')) {
      if (!url.includes(hostName)) {
        dataUrl = {
          target: '_blank',
          rel: 'noreferrer',
        }
      }
    }
    return dataUrl
  }

  return (value: string) => checkUrl(value, hostName)
}
