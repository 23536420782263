import { useMatches } from '@remix-run/react'
import { RouteData } from '../types'
import { TopicRouteData } from '../utils/topicHelper'
import { FeatureTogglesConfig } from '@scm/feature-toggles-client'

export const useBridgedMediaCheck = (
  data: RouteData | undefined,
  topicData: TopicRouteData | undefined,
  featureToggles: FeatureTogglesConfig,
) => {
  const matches = useMatches()
  if (!featureToggles.bridgedMediaEnabled) return false

  const routeIds = [
    'routes/program.$slug',
    'routes/publications.$slug.$issue',
    'routes/series.$slug',
    'routes/keyword.$slug',
  ]
  const isRelevantRoute = matches.some(match => routeIds.includes(match.id))

  const validTypes = ['topic', 'premier-page']
  const isTopicOrPremierPage =
    topicData?.type && validTypes.includes(topicData?.type)

  const isSponsored = Boolean(data?.template?.sponsored?.isSponsored)
  const isPaidContent = Boolean(data?.template?.paidGating?.isPaidContent)
  const validDataTypes = [
    'article',
    'podcast',
    'slideshow',
    'video',
    'topic',
    'journal',
    'recipe',
    'multi_section_article',
  ]
  const isDataPage =
    data?.metadata?.type &&
    validDataTypes.includes(data?.metadata?.type) &&
    !(isPaidContent || isSponsored)

  const isBridgedMediaEnabled =
    isRelevantRoute || isTopicOrPremierPage || isDataPage
  return Boolean(isBridgedMediaEnabled)
}
