import { OneTrustCookieBannerProps } from './type'
import { actions, store } from '@scm/redux-store'
import { useWindowListener } from '@scm/event-listener'

declare global {
  interface WindowEventMap {
    cookieBannerConsentChanged: CustomEvent
  }
}

export const OneTrustCookieBanner = (props: OneTrustCookieBannerProps) => {
  useWindowListener('cookieBannerConsentChanged', () => {
    store.dispatch(actions.config.oneTrustScriptLoaded())
  })
  return (
    <>
      <script
        type="text/javascript"
        src={`https://cdn.cookielaw.org/consent/${props.siteId}/OtAutoBlock.js`}
      />
      <script
        type="text/javascript"
        src={'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'}
        data-domain-script={props.siteId}
      />
      {/*OnetrustActiveGroups: https://community.cookiepro.com/s/article/UUID-66bcaaf1-c7ca-5f32-6760-c75a1337c226?language=en_US */}
      {/*OptanonWrapper: The wrapper is executed on each page load, or whenever the user saves changes to the privacy settings in the Preference Center.
        https://my.onetrust.com/articles/en_US/Knowledge/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca */}
      {/* the isInitialLoading flag is needed to not refresh the ad slots when the user reloads the page after the cookies have been accepted.
        This flag is true when the user visits the page for the first time, and the first if-condition is fired because targeting cookies were not set yet
        and the flag changes to false - so the ads are not refreshed.
        When the user accepts the cookies, this flag is still false so we get to refresh the ad slots.
        When the user refreshes the page with already accepted cookies, the isInitialLoading is true again and the execution stops at the second if-condition
        and the ad slots don't refresh. */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          function OptanonWrapper() {
            const isClosed = OneTrust.IsAlertBoxClosed()
            const allowPersonalised = /,C0004,/.test(OnetrustActiveGroups)
            console.log('cookieBannerConsentChanged emit', isClosed, allowPersonalised)
            const event = new CustomEvent('cookieBannerConsentChanged', {
              detail: {
                allowPersonalised,
                isClosed
              }
            })
            window.dispatchEvent(event)
          }`,
        }}
      />
    </>
  )
}
