import React from 'react'

import { Bem } from '@scm/ui-core'

export interface RegistrationSectionProps {
  children: React.ReactNode
  linkSectionLength: number
}

const registrationSection = Bem('RegistrationSection')
export const RegistrationSection = ({
  children,
  linkSectionLength,
}: RegistrationSectionProps) => {
  return (
    <div className={registrationSection({ linkSectionLength })}>
      <h5 className={registrationSection('Title')}>Join Us</h5>

      {children}
    </div>
  )
}
