import { useLocation } from '@remix-run/react'
import { FC, useEffect, useState } from 'react'
import { IrisIdentity, useAuthentication } from '@scm/ui-authentication'
import { InformaBar } from '@scm/ui-component-informa-bar'
import { SocialShare } from '@scm/ui-component-social-share'
import { SponsorBar } from '@scm/ui-component-sponsor-bar'
import { Bem } from '@scm/ui-core'
import { Footer } from '@scm/ui-module-footer'
import { LoggedIn } from '@scm/ui-module-logged-in'
import { MegaMenu, SideNav } from '@scm/ui-module-mega-menu'
import { NavBase, Navigation } from '@scm/ui-module-navigation'
import { NewsletterSignupPromo } from '@scm/ui-module-newsletter-signup-promo'
import { Registration } from '@scm/ui-module-registration'

import { AuthenticationActionsProps, LayoutProps } from './type'
import { selectors } from '@scm/redux-store'

const layout = Bem('Layout')

export const Layout: FC<LayoutProps & { children?: any }> = ({
  nav,
  informaBar,
  megaMenu,
  footer,
  children,
  navBase,
  socialLinks,
  isSponsorBarEnabled,
  registrationText,
  isPaidContent,
  authConfig,
}) => {
  const { authFlow, withNewsletterPromo } = selectors.useAuth()
  const { elnWithoutNewsletter } = selectors.useFeatureToggles()
  const [isOpenNewsletter, setIsOpenNewsletter] = useState(false)

  useEffect(() => {
    if (
      authFlow === 'register' &&
      withNewsletterPromo &&
      !elnWithoutNewsletter
    ) {
      // Open the newsletter sign up for only one time
      setIsOpenNewsletter(true)
    }
  }, [authFlow, elnWithoutNewsletter, withNewsletterPromo])
  return (
    <div data-template="layout" className={layout()}>
      <Navigation
        header={<InformaBar {...informaBar} />}
        mainMenuItems={nav.mainMenuItems}
        secondaryMenu={nav.secondaryMenu}
        megaMenu={close => (
          <MegaMenu
            largeMenu={megaMenu.largeMenu}
            smallMenu={megaMenu.smallMenu}
            close={close}
          >
            <SideNav
              sections={megaMenu.sideNavSections}
              registrationText={registrationText}
              registration={
                <RegistrationActions
                  variant="megaMenu"
                  isPaidContent={isPaidContent}
                />
              }
              social={
                <SocialShare
                  variant="layout"
                  icons={socialLinks}
                  location="mega_menu"
                />
              }
            />
          </MegaMenu>
        )}
        navBase={(openMegaMenu, isOverOffset) => (
          <NavBase
            logo={navBase.logo}
            openMegaMenu={openMegaMenu}
            isOverOffset={isOverOffset}
          >
            <NewsletterSignupPromo
              title=""
              message=""
              layoutVariant="header"
              isOpenNewsletter={isOpenNewsletter}
              social={
                <SocialShare
                  variant="layout"
                  icons={socialLinks}
                  location="main_menu"
                  className={layout('SocialShare')}
                  classNameLink={layout('SocialLink')}
                />
              }
            />
            <AuthenticationActions
              variant="header"
              isPaidContent={isPaidContent}
            />
          </NavBase>
        )}
      />
      {isSponsorBarEnabled && <SponsorBar />}

      <section className={layout('Section')}>
        <IrisIdentity
          configid={authConfig?.authenticationConfigId}
          isautomated={authConfig?.disableCaptcha ? 'true' : undefined}
        />
        {children}
      </section>

      <Footer
        logo={footer.logo}
        linkSections={footer.linkSections}
        brandLinks={footer.brandLinks}
        copyright={footer.copyright}
        registration={
          <RegistrationActions variant="footer" isPaidContent={isPaidContent} />
        }
        social={
          <SocialShare
            variant="layout"
            icons={socialLinks}
            location="footer_menu"
          />
        }
      />
    </div>
  )
}

const RegistrationActions: FC<AuthenticationActionsProps> = ({
  variant,
  isPaidContent,
}) => {
  return (
    <>
      <div className={layout('Register', { variant })}>
        <AuthenticationActions
          variant={variant}
          isPaidContent={isPaidContent}
        />
      </div>
      <div className={layout('NewsLetter', { variant })}>
        <NewsletterSignupPromo title="" message="" layoutVariant={variant} />
      </div>
    </>
  )
}

const AuthenticationActions: FC<AuthenticationActionsProps> = ({
  variant,
  isPaidContent,
}) => {
  const { register, login, logout } = useAuthentication()

  const { user, isClientLoaded } = selectors.useAuth()
  const { iirisAuthentication } = selectors.useFeatureToggles()
  const { pathname } = useLocation()

  // Return null when has path is sso/callback
  // Reference to https://itmebusiness.atlassian.net/browse/STAN-5482
  if (!iirisAuthentication || pathname.includes('/sso/callback')) {
    return null
  }
  if (!isClientLoaded) return null

  if (user) return <LoggedIn onSignOut={logout} variant={variant} />

  if (!pathname.startsWith('/register'))
    return (
      <Registration
        variant={variant}
        onRegister={register}
        onSignIn={login}
        isPaidContent={isPaidContent}
      />
    )

  return null
}
