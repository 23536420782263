import { useLocation } from '@remix-run/react'
import { useEffect } from 'react'
const CURRENT = 'currentURL'
const PREVIOUS = 'previousURL'
export function useLocationHistory() {
  const { pathname } = useLocation()
  useEffect(() => {
    const currentURL = sessionStorage.getItem(CURRENT)
    if (!currentURL) {
      sessionStorage.setItem(CURRENT, pathname)
      sessionStorage.setItem(PREVIOUS, '/')
    } else if (pathname !== currentURL) {
      sessionStorage.setItem(CURRENT, pathname)
      sessionStorage.setItem(PREVIOUS, currentURL)
    }
  }, [pathname])
}
