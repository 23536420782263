import { Bem } from '@scm/ui-core'
import { useNavigation } from '@remix-run/react'
import { selectors } from '@scm/redux-store'

const spinner = Bem('Spinner')

export const Spinner = () => {
  const navigation = useNavigation()
  const { spinnerLoader } = selectors.useLayout()

  const isLoadingWithSpinner =
    navigation.state === 'loading' && navigation.location.state?.['withSpinner']
  const shouldShowSpinner = isLoadingWithSpinner || spinnerLoader

  return shouldShowSpinner ? (
    <div className={spinner('Overlay')}>
      <div className={spinner()} />
      <p className={spinner('Caption')}>Please Wait...</p>
    </div>
  ) : null
}
