interface IrisIdentityProps {
  configid?: string
  isautomated?: 'true'
}

export const IrisIdentity = (props: IrisIdentityProps) => {
  return (
    <div
      id="iiris-identity"
      suppressHydrationWarning={true}
      data-testid="iris-identity"
      {...props}
    />
  )
}
