import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { SideNavProps } from './type'
import { selectors } from '@scm/redux-store'

export const sideNav = Bem('SideNav')

export const SideNav = ({
  registration,
  social,
  sections,
  registrationText,
}: SideNavProps) => {
  const { user } = selectors.useAuth()
  const { iirisAuthentication } = selectors.useFeatureToggles()

  return (
    <div className={sideNav()}>
      <div className={sideNav('ContentWrap')}>
        {registration && (
          <div
            className={sideNav('Registration')}
            data-testid="side-nav-registration"
          >
            <div className={sideNav('RegistrationTitle')}>
              {user && iirisAuthentication
                ? `Hello, ${user?.first_name}`
                : 'Join Us'}
            </div>
            <div className={sideNav('RegistrationContent')}>{registration}</div>
            <div className={sideNav('RegistrationText')}>
              {registrationText
                ? registrationText
                : 'Gain easy access to downloads, webinars, magazine articles, and more!'}
            </div>
          </div>
        )}

        {social && (
          <div className={sideNav('Social')}>
            <div className={sideNav('SocialTitle')}>Follow Us</div>
            <div className={sideNav('SocialContent')}>{social}</div>
          </div>
        )}

        {sections.map(({ title, subMenu }, index) => (
          <div className={sideNav('Section')} key={index}>
            <div className={sideNav('SectionTitle')}>{title}</div>

            {subMenu.map((link, index) => (
              <div key={index} className={sideNav('SectionSubMenu')}>
                <Link to={link.url ?? ''}>{link.title}</Link>
              </div>
            ))}
          </div>
        ))}

        {social && (
          <div className={sideNav('SocialMobile')}>
            <div className={sideNav('SocialTitle')}>Follow Us</div>
            <div className={sideNav('SocialContent')}>{social}</div>
          </div>
        )}
      </div>
      {registration && (
        <div className={sideNav('RegistrationMobile')}>{registration}</div>
      )}
    </div>
  )
}
