import aibusiness from '@scm/ui-brand-aibusiness/build/brand.generated.css?url'
import batterytech from '@scm/ui-brand-batterytech/build/brand.generated.css?url'
import beefmagazine from '@scm/ui-brand-beefmagazine/build/brand.generated.css?url'
import bioprocessinternational from '@scm/ui-brand-bioprocessinternational/build/brand.generated.css?url'
import channelfutures from '@scm/ui-brand-channelfutures/build/brand.generated.css?url'
import constructionext from '@scm/ui-brand-constructionext/build/brand.generated.css?url'
import darkreading from '@scm/ui-brand-darkreading/build/brand.generated.css?url'
import datacenterknowledge from '@scm/ui-brand-datacenterknowledge/build/brand.generated.css?url'
import demo from '@scm/ui-brand-demo/build/brand.generated.css?url'
import designnews from '@scm/ui-brand-designnews/build/brand.generated.css?url'
import farmprogress from '@scm/ui-brand-farmprogress/build/brand.generated.css?url'
import feedstuffs from '@scm/ui-brand-feedstuffs/build/brand.generated.css?url'
import foodbeverageinsider from '@scm/ui-brand-foodbeverageinsider/build/brand.generated.css?url'
import foodservicedirector from '@scm/ui-brand-foodservicedirector/build/brand.generated.css?url'
import gamedeveloper from '@scm/ui-brand-gamedeveloper/build/brand.generated.css?url'
import informationweek from '@scm/ui-brand-informationweek/build/brand.generated.css?url'
import insideselfstorage from '@scm/ui-brand-insideselfstorage/build/brand.generated.css?url'
import iot from '@scm/ui-brand-iot/build/brand.generated.css?url'
import itprotoday from '@scm/ui-brand-itprotoday/build/brand.generated.css?url'
import licenseglobal from '@scm/ui-brand-licenseglobal/build/brand.generated.css?url'
import lightreading from '@scm/ui-brand-lightreading/build/brand.generated.css?url'
import mddi from '@scm/ui-brand-mddi/build/brand.generated.css?url'
import nationalhogfarmer from '@scm/ui-brand-nationalhogfarmer/build/brand.generated.css?url'
import naturalproductsinsider from '@scm/ui-brand-naturalproductsinsider/build/brand.generated.css?url'
import supplysidefbj from '@scm/ui-brand-supplysidefbj/build/brand.generated.css?url'
import supplysidesj from '@scm/ui-brand-supplysidesj/build/brand.generated.css?url'
import networkcomputing from '@scm/ui-brand-networkcomputing/build/brand.generated.css?url'
import newhope from '@scm/ui-brand-newhope/build/brand.generated.css?url'
import packagingdigest from '@scm/ui-brand-packagingdigest/build/brand.generated.css?url'
import plasticstoday from '@scm/ui-brand-plasticstoday/build/brand.generated.css?url'
import powderbulksolids from '@scm/ui-brand-powderbulksolids/build/brand.generated.css?url'
import quantum from '@scm/ui-brand-quantum/build/brand.generated.css?url'
import restauranthospitality from '@scm/ui-brand-restauranthospitality/build/brand.generated.css?url'
import supermarketnews from '@scm/ui-brand-supermarketnews/build/brand.generated.css?url'
import telecoms from '@scm/ui-brand-telecoms/build/brand.generated.css?url'
import wardsauto from '@scm/ui-brand-wardsauto/build/brand.generated.css?url'
import waste360 from '@scm/ui-brand-waste360/build/brand.generated.css?url'
import wealthmanagement from '@scm/ui-brand-wealthmanagement/build/brand.generated.css?url'
import organicproducenetwork from '@scm/ui-brand-organicproducenetwork/build/brand.generated.css?url'
import seatrademaritime from '@scm/ui-brand-seatrademaritime/build/brand.generated.css?url'
import seatradecruise from '@scm/ui-brand-seatradecruise/build/brand.generated.css?url'
import vitafoodsinsights from '@scm/ui-brand-vitafoodsinsights/build/brand.generated.css?url'
import figlobal from '@scm/ui-brand-figlobal/build/brand.generated.css?url'
import datacenterknowledge2 from '@scm/ui-brand-datacenterknowledge2/build/brand.generated.css?url'
import connectingafrica from '@scm/ui-brand-connectingafrica/build/brand.generated.css?url'
import theaestheticguide from '@scm/ui-brand-theaestheticguide/build/brand.generated.css?url'
import nrn from '@scm/ui-brand-nrn/build/brand.generated.css?url'
import bioxconomy from '@scm/ui-brand-bioxconomy/build/brand.generated.css?url'
import americancityandcounty from '@scm/ui-brand-americancityandcounty/build/brand.generated.css?url'
import urgentcomm from '@scm/ui-brand-urgentcomm/build/brand.generated.css?url'
import catersource from '@scm/ui-brand-catersource/build/brand.generated.css?url'
import specialevents from '@scm/ui-brand-specialevents/build/brand.generated.css?url'
import worldhealthexhibitions from '@scm/ui-brand-worldhealthexhibitions/build/brand.generated.css?url'
import nojitter from '@scm/ui-brand-nojitter/build/brand.generated.css?url'
import fintechfutures from '@scm/ui-brand-fintechfutures/build/brand.generated.css?url'
// PLOP: BRAND STYLES IMPORT
import { Brand, UIConfig } from '@scm/ui-core'

export const brandsCssMap = new Map([
  [Brand.AI_BUSINESS, aibusiness],
  [Brand.BEEF_MAGAZINE, beefmagazine],
  [Brand.GAME_DEVELOPER, gamedeveloper],
  [Brand.FARM_PROGRESS, farmprogress],
  [Brand.FEED_STUFFS, feedstuffs],
  [Brand.INFORMATION_WEEK, informationweek],
  [Brand.LIGHT_READING, lightreading],
  [Brand.DEMO, demo],
  [Brand.IOT, iot],
  [Brand.QUANTUM, quantum],
  [Brand.LICENSE_GLOBAL, licenseglobal],
  [Brand.NATIONAL_HOG_FARMER, nationalhogfarmer],
  [Brand.NATURAL_PRODUCTS_INSIDER, naturalproductsinsider],
  [Brand.SUPPLY_SIDE_FBJ, supplysidefbj],
  [Brand.SUPPLY_SIDE_SJ, supplysidesj],
  [Brand.FOOD_BEVERAGE_INSIDER, foodbeverageinsider],
  [Brand.NEW_HOPE, newhope],
  [Brand.DARK_READING, darkreading],
  [Brand.MASTER, farmprogress],
  [Brand.DEVELOPMENT, farmprogress],
  [Brand.CHANNEL_FUTURES, channelfutures],
  [Brand.TELECOMS, telecoms],
  [Brand.BATTERY_TECH, batterytech],
  [Brand.POWDER_BULK_SOLIDS, powderbulksolids],
  [Brand.DESIGN_NEWS, designnews],
  [Brand.WASTE360, waste360],
  [Brand.CONSTRUCTIO_NEXT, constructionext],
  [Brand.PACKAGING_DIGEST, packagingdigest],
  [Brand.PLASTICS_TODAY, plasticstoday],
  [Brand.MDDI, mddi],
  [Brand.INSIDE_SELF_STORAGE, insideselfstorage],
  [Brand.BIO_PROCESS_INTERNATIONAL, bioprocessinternational],
  [Brand.DATA_CENTER_KNOWLEDGE, datacenterknowledge],
  [Brand.IT_PRO_TODAY, itprotoday],
  [Brand.WARDS_AUTO, wardsauto],
  [Brand.NETWORK_COMPUTING, networkcomputing],
  [Brand.SUPERMARKET_NEWS, supermarketnews],
  [Brand.FOOD_SERVICE_DIRECTOR, foodservicedirector],
  [Brand.BRANCHING, aibusiness],
  [Brand.TEST, aibusiness],
  [Brand.TEST2, aibusiness],
  [Brand.TEST3, aibusiness],
  [Brand.FEATURE, aibusiness],
  [Brand.TEST_AUTOMATION, aibusiness],
  [Brand.RESTAURANT_HOSPITALITY, restauranthospitality],
  [Brand.WEALTH_MANAGEMENT, wealthmanagement],
  [Brand.ORGANIC_PRODUCE_NETWORK, organicproducenetwork],
  [Brand.SEATRADE_MARITIME, seatrademaritime],
  [Brand.SEATRADE_CRUISE, seatradecruise],
  [Brand.VITA_FOODS_INSIGHTS, vitafoodsinsights],
  [Brand.FI_GLOBAL, figlobal],
  [Brand.DATA_CENTER_KNOWLEDGE_2, datacenterknowledge2],
  [Brand.CONNECTING_AFRICA, connectingafrica],
  [Brand.THE_AESTHETIC_GUIDE, theaestheticguide],
  [Brand.NATION_S_RESTAURANT_NEWS, nrn],
  [Brand.BIO_XCONOMY, bioxconomy],
  [Brand.AMERICAN_CITY_COUNTY, americancityandcounty],
  [Brand.URGENT_COMMS, urgentcomm],
  [Brand.CATERSOURCE, catersource],
  [Brand.SPECIAL_EVENTS, specialevents],
  [Brand.WORLD_HEALTH_EXHIBITIONS_INSIGHTS, worldhealthexhibitions],
  [Brand.NO_JITTER, nojitter],
  [Brand.FIN_TECH_FUTURES, fintechfutures],
  // PLOP: BRAND STYLES MAPPING
])

const brandsKeys = Object.values(Brand)
interface Payload {
  overrideBrandStyling: Brand
  brand: Brand
  font: UIConfig['font']
}

export const getBrand = (brand: Brand): string => {
  return brandsCssMap.get(brand) || Brand.DEMO
}

export const StyleSheet = ({ overrideBrandStyling, brand, font }: Payload) => {
  const brandStyle = brandsKeys.includes(overrideBrandStyling)
    ? overrideBrandStyling
    : brand

  return (
    <>
      <link rel="stylesheet" href={getBrand(brandStyle)} />
      <link rel="stylesheet" href={font?.secondary.url} />
      <link rel="stylesheet" href={font?.primary.url} />
    </>
  )
}
