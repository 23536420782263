import { AdPos, Ads } from '@scm/ads'
import { SponsorLogos } from '@scm/ui-component-sponsor-logos'
import { Bem } from '@scm/ui-core'
import { useScreen } from '@scm/ui-tailwind'

const sponsorBar = Bem('SponsorBar')

export const checkActive = (logos: AdPos[]) => {
  let active = false
  logos.forEach(pos => {
    if (Ads.hooks.useIsAdRender(pos) === 'show') {
      active = true
      return active
    }
  })
  return active
}

export const SponsorBar = () => {
  const logos: AdPos[] = [
    '100_1v',
    '100_2v',
    '100_3v',
    '100_4v',
    '100_5v',
    '100_6v',
    '100_7v',
    '100_8v',
  ]

  const active = checkActive(logos)
  const screen = useScreen()

  return (
    <div className={sponsorBar({ active })} data-component="sponsor-bar">
      <div className={sponsorBar('Wrapper', { active })}>
        <span
          className={sponsorBar('Sponsored', { active })}
          data-testid="sponsored"
        >
          Sponsored By
        </span>
        <div className={sponsorBar('LogosWrapper')}>
          {(screen === 'lg' || screen === 'xl') && (
            <SponsorLogos logos={logos} />
          )}
        </div>
      </div>
    </div>
  )
}
