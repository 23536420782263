import { Icon } from '@scm/ui-component-icon'
import { SearchBar } from '@scm/ui-component-search-bar'
import { Bem } from '@scm/ui-core'

import { LargeGroup, LargeGroupMobile } from './largeGroup'
import { SmallGroup, SmallGroupLg } from './smallGroup'
import { MegaMenuProps } from './type'

export const megaMenu = Bem('MegaMenu')

export const MegaMenu = ({
  largeMenu,
  smallMenu,
  close,
  children,
}: MegaMenuProps) => {
  return (
    <div data-module="mega-menu" className={megaMenu()}>
      <div className={megaMenu('Content')}>
        <div className={megaMenu('HeaderRow')}>
          <div
            className={megaMenu('CloseButton')}
            role="presentation"
            onClick={close}
          >
            <Icon icon="Close" className={megaMenu('CloseButtonIcon')} />
          </div>
          <SearchBar
            classNames={{ searchBtn: megaMenu('SearchBarButton') }}
            variant={'megaMenu'}
            autoFocusInput={false}
          />
        </div>
        <div className={megaMenu('Title')}>Topics</div>
        <div className={megaMenu('Body')}>
          <div className={megaMenu('Group')}>
            <LargeGroup megaMenu={largeMenu} />
            <LargeGroupMobile megaMenu={largeMenu} />
            {smallMenu?.length > 0 && <SmallGroup megaMenu={smallMenu} />}
          </div>
          {/* Show on screen lg */}
          <SmallGroupLg megaMenu={smallMenu} />
          {children}
        </div>
        <div className={megaMenu('BottomPlaceholder')} />
      </div>
    </div>
  )
}
