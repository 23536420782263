import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { useOpenTab } from './hooks'
import { SmallMenuProps } from './type'

interface SmallGroupProps {
  megaMenu: SmallMenuProps[]
}

const smallGroup = Bem('SmallGroup')

export const SmallGroup = ({ megaMenu }: SmallGroupProps) => {
  const checkOpenTab = useOpenTab()

  return (
    <div className={smallGroup()}>
      {megaMenu?.map((menu, index) => (
        <div
          className={smallGroup('ContentWrapper')}
          key={`${menu.title}__${index}`}
        >
          <div className={smallGroup('Title')}>{menu.title}</div>
          <div className={smallGroup('Content')}>
            <nav
              className={smallGroup('Nav')}
              aria-label={`small-mobile-menu-nav-${index}`}
            >
              <ol className={smallGroup('NavItemsList')}>
                {menu.subMenu?.map((sub, subIndex) => (
                  <li
                    className={smallGroup('NavItem')}
                    key={`${sub}__${subIndex}`}
                  >
                    <Link
                      className={smallGroup('NavItemLink')}
                      to={sub.url}
                      target={checkOpenTab(sub.url).target}
                      rel={checkOpenTab(sub.url).rel}
                    >
                      {sub.title}
                    </Link>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      ))}
    </div>
  )
}

const smallGroupLg = Bem('SmallGroupLg')

export const SmallGroupLg = ({ megaMenu }: SmallGroupProps) => {
  const checkOpenTab = useOpenTab()

  return (
    <div className={smallGroupLg()}>
      {megaMenu?.map((menu, index) => (
        <div key={`${menu.title}__${index}`}>
          <div
            className={smallGroupLg('Title')}
            data-testid="small-group-title"
          >
            {menu.title}
          </div>
          <div className={smallGroupLg('Content')}>
            <nav
              className={smallGroupLg('Nav')}
              aria-label={`small-desktop-menu-nav-${index}`}
            >
              <ol className={smallGroupLg('NavItemsList')}>
                {menu.subMenu?.map((sub, subIndex) => (
                  <li
                    key={`${sub.title}__${subIndex}`}
                    className={smallGroupLg('NavItem')}
                  >
                    <Link
                      className={smallGroupLg('NavItemLink')}
                      to={sub.url}
                      target={checkOpenTab(sub.url).target}
                      rel={checkOpenTab(sub.url).rel}
                      data-testid="small-group-nav-item-link"
                    >
                      {sub.title}
                    </Link>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      ))}
    </div>
  )
}
