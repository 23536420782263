// @ts-nocheck
import { Brand } from '@scm/ui-core'

import aibusiness from './brands/aibusiness.ico'
import batterytech from './brands/batterytech.ico'
import bioprocessinternational from './brands/bioprocessinternational.ico'
import channelfutures from './brands/channelfutures.ico'
import constructionext from './brands/constructionext.ico'
import darkreading from './brands/darkreading.ico'
import datacenterknowledge from './brands/datacenterknowledge.ico'
import demo from './brands/demo.ico'
import designnews from './brands/designnews.ico'
import foodbeverageinsider from './brands/foodbeverageinsider.ico'
import foodservicedirector from './brands/foodservicedirector.ico'
import gamedeveloper from './brands/gamedeveloper.ico'
import informationweek from './brands/informationweek.ico'
import insideselfstorage from './brands/insideselfstorage.ico'
import iot from './brands/iot.ico'
import itprotoday from './brands/itprotoday.ico'
import licenseglobal from './brands/licenseglobal.ico'
import lightreading from './brands/lightreading.ico'
import markets from './brands/markets.ico'
import mddi from './brands/mddi.ico'
import naturalproductsinsider from './brands/naturalproductsinsider.ico'
import supplysidefbj from './brands/supplysidefbj.ico'
import supplysidesj from './brands/supplysidesj.ico'
import networkcomputing from './brands/networkcomputing.ico'
import newhope from './brands/newhope.ico'
import organicProduceNetwork from './brands/organicProduceNetwork.ico'
import packagingDigest from './brands/packagingdigest.ico'
import plasticsToday from './brands/plasticstoday.ico'
import powderBulkSolids from './brands/powderBulkSolids.ico'
import restauranthospitality from './brands/restauranthospitality.ico'
import supermarketnews from './brands/supermarketnews.ico'
import telecoms from './brands/telecoms.ico'
import wardsauto from './brands/wardsauto.ico'
import waste360 from './brands/waste360.ico'
import wealthmanagement from './brands/wealthmanagement.ico'
import seatrademaritime from './brands/seatrademaritime.ico'
import seatradecruise from './brands/seatradecruise.ico'
import figlobal from './brands/figlobal.ico'
import vitafoodsinsights from './brands/vitafoodsinsights.ico'
import connectingafrica from './brands/connectingafrica.ico'
import theaestheticguide from './brands/theaestheticguide.ico'
import bioxconomy from './brands/bioxconomy.ico'
import nrn from './brands/nrn.ico'
import americancityandcounty from './brands/americancityandcounty.ico'
import catersource from './brands/catersource.ico'
import urgentcomm from './brands/urgentcomm.ico'
import specialevents from './brands/specialevents.ico'
import fintechfutures from './brands/fintechfutures.ico'
import nojitter from './brands/nojitter.ico'
import worldhealthexhibitions from './brands/worldhealthexhibitions.ico'

export const brandFavIconMap = new Map<Brand, string>([
  [Brand.AI_BUSINESS, aibusiness],
  [Brand.LIGHT_READING, lightreading],
  [Brand.IOT, iot],
  [Brand.INFORMATION_WEEK, informationweek],
  [Brand.FARM_PROGRESS, markets],
  [Brand.NATIONAL_HOG_FARMER, markets],
  [Brand.BEEF_MAGAZINE, markets],
  [Brand.FEED_STUFFS, markets],
  [Brand.NATURAL_PRODUCTS_INSIDER, naturalproductsinsider],
  [Brand.SUPPLY_SIDE_FBJ, supplysidefbj],
  [Brand.SUPPLY_SIDE_SJ, supplysidesj],
  [Brand.NETWORK_COMPUTING, networkcomputing],
  [Brand.FOOD_BEVERAGE_INSIDER, foodbeverageinsider],
  [Brand.NEW_HOPE, newhope],
  [Brand.BATTERY_TECH, batterytech],
  [Brand.POWDER_BULK_SOLIDS, powderBulkSolids],
  [Brand.DESIGN_NEWS, designnews],
  [Brand.CONSTRUCTIO_NEXT, constructionext],
  [Brand.ORGANIC_PRODUCE_NETWORK, organicProduceNetwork],
  [Brand.PACKAGING_DIGEST, packagingDigest],
  [Brand.PLASTICS_TODAY, plasticsToday],
  [Brand.MDDI, mddi],
  [Brand.CHANNEL_FUTURES, channelfutures],
  [Brand.DARK_READING, darkreading],
  [Brand.WASTE360, waste360],
  [Brand.INSIDE_SELF_STORAGE, insideselfstorage],
  [Brand.BIO_PROCESS_INTERNATIONAL, bioprocessinternational],
  [Brand.LICENSE_GLOBAL, licenseglobal],
  [Brand.TELECOMS, telecoms],
  [Brand.SUPERMARKET_NEWS, supermarketnews],
  [Brand.GAME_DEVELOPER, gamedeveloper],
  [Brand.FOOD_SERVICE_DIRECTOR, foodservicedirector],
  [Brand.RESTAURANT_HOSPITALITY, restauranthospitality],
  [Brand.WEALTH_MANAGEMENT, wealthmanagement],
  [Brand.BRANCHING, markets],
  [Brand.TEST, markets],
  [Brand.TEST2, markets],
  [Brand.TEST3, markets],
  [Brand.TEST_AUTOMATION, markets],
  [Brand.FEATURES, markets],
  [Brand.IT_PRO_TODAY, itprotoday],
  [Brand.DATA_CENTER_KNOWLEDGE, datacenterknowledge],
  [Brand.WARDS_AUTO, wardsauto],
  [Brand.SEATRADE_MARITIME, seatrademaritime],
  [Brand.SEATRADE_CRUISE, seatradecruise],
  [Brand.FI_GLOBAL, figlobal],
  [Brand.VITA_FOODS_INSIGHTS, vitafoodsinsights],
  [Brand.CONNECTING_AFRICA, connectingafrica],
  [Brand.THE_AESTHETIC_GUIDE, theaestheticguide],
  [Brand.BIO_XCONOMY, bioxconomy],
  [Brand.NATION_S_RESTAURANT_NEWS, nrn],
  [Brand.AMERICAN_CITY_COUNTY, americancityandcounty],
  [Brand.CATERSOURCE, catersource],
  [Brand.URGENT_COMMS, urgentcomm],
  [Brand.SPECIAL_EVENTS, specialevents],
  [Brand.FIN_TECH_FUTURES, fintechfutures],
  [Brand.NO_JITTER, nojitter],
  [Brand.WORLD_HEALTH_EXHIBITIONS_INSIGHTS, worldhealthexhibitions],
])

export const getFavicon = (brand: Brand): string => {
  if (brandFavIconMap.has(brand)) {
    return brandFavIconMap.get(brand)
  }

  return demo
}

export interface FaviconProps {
  brand: Brand
}

export const Favicon = ({ brand }: FaviconProps) => {
  return <link rel="icon" type="image/x-icon" href={getFavicon(brand)} />
}
